import React from "react";
import { Container, Row } from "react-bootstrap";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";
import Getdagcoins from "../components/get-dagcoins";
import Headertransparent from "../components/headertransparent";
import Layout from "../components/layout";
import { Seo } from "../components/seo";

function Page({ data }) {
  var dagSlider = data.allPrismicArticle.edges;
  var handleOnDragStart = (e) => e.preventDefault();

  var NextUid = data.prismicArticle.alternate_languages.id;

  if (data.prismicArticle.lang === "en-us") {
    var NextUrl = "/et/ecosystem/" + NextUid;
  } else if (data.prismicArticle.lang === "et-ee") {
    NextUrl = "/ecosystem/" + NextUid;
  } else {
    NextUrl = "wrong lang";
  }

  return (
    <Layout htmllang={data.prismicArticle.lang}>
      <Headertransparent
        htmllang={data.prismicArticle.lang}
        langswitcher={NextUrl}
      />
      <main className="container ecosystempage single-ecosystem-page">
        <Container className="above fullwidth">
          <GatsbyImage
            alt="Dagcoin"
            image={data.homebg.childImageSharp.gatsbyImageData}
          />
          <Row>
            <h1>
              {" "}
              {data.prismicArticle.data.image.url && (
                <div className="single-ecosystemimage">
                  <img
                    src={data.prismicArticle.data.image.url}
                    alt="Dagcoin"
                    className="img-fluid"
                  />
                </div>
              )}
              {data.prismicArticle.data.title.text}
            </h1>
          </Row>
        </Container>
        <div className="ecosystemcontentwrap">
          <Container className="ecosystemcontent">
            <div className="ecosystem-text">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.prismicArticle.data.content.html,
                }}
              />
            </div>
            <div className="ecosystemslider">
              <AliceCarousel
                mouseTrackingEnabled
                dotsDisabled={0}
                responsive={{ 1024: { items: 5 } }}
              >
                {dagSlider.map((value, index) => {
                  return (
                    <div key={index}>
                      <div
                        role="button"
                        tabIndex={0}
                        className="ecosliders"
                        onDragStart={handleOnDragStart}
                      >
                        {/* <Link to={currentUrl + value.node.uid}> */}
                        <img src={value.node.data.image.url} alt="Dagcoin" />
                        <h5>{value.node.data.title.text}</h5>
                        <h6>{value.node.data.category}</h6>
                        {/* </Link> */}
                      </div>
                    </div>
                  );
                })}
              </AliceCarousel>
            </div>
          </Container>
        </div>
      </main>
    </Layout>
  );
}

export const Head = ({ data }) => {
  return (
    <>
      <Seo
        // TODO: Add description
        pathname={data.prismicArticle.uid}
        // TODO: Title should come from "seo_title" query
        title={data.prismicArticle.data.title.text}
        // lang={data.prismicArticle.lang}
        description={data.prismicArticle.data.content.text}
      />
    </>
  );
};

export const query = graphql`
  query ($id: String, $lang: String) {
    prismicArticle(id: { eq: $id }, lang: { eq: $lang }) {
      uid
      id
      lang
      alternate_languages {
        id
        lang
        type
        uid
      }
      data {
        content {
          html
          text
        }
        image {
          url
        }
        title {
          text
        }
      }
    }
    prismicEcosystem {
      data {
        dagcoin_technology {
          dagcoin_technology_title {
            text
          }
          ecosystem_icon {
            url
          }
          ecosystem_link {
            slug
          }
          ecosystem_shortdescription {
            text
          }
        }
      }
    }
    allPrismicArticle(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          data {
            category
            title {
              text
            }
            image {
              url
            }
          }
          uid
          lang
        }
      }
    }
    homebg: file(relativePath: { eq: "ecosystem.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
  }
`;
export default Page;
